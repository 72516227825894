<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            A telefonos nyomkövető mint kétélű fegyver
          </h2>
          <p class="hashText">- Tudom, hol vagy!</p>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap">
          <div class="maintText__txt-content">
            <p class="text">
              Amikor a telefonos nyomkövetőkről beszélünk, a legtöbbünknek az aggódó szülők, vagy a féltékeny párok jutnak eszünkbe. Vajon a negatívumokkal szemben milyen pozitív hozadékai lehetnek a nyomkövetésnek, és mikre érdemes odafigyelni? Cikkünkben erre a kérdésre keressük a választ.
  
            </p>
            <p class="text">
                Akár biztonsági okokból, akár puszta kíváncsiságból figyeljük meg egy másik ember telefonját, fontolóra kell vennünk az alábbiakat:
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap">
            <img src="images/location.png" class="w-100 margin-top-sm-44"/>
        </b-col>
        <b-col cols="12" lg="12" class="side-gap">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text">Fordítsuk meg a helyzetet! Vajon, hogyan éreznénk magunkat, ha minket figyelne meg a párunk vagy egy családtagunk? Hogyan értelmeznénk ezt a megfigyelést? Nem mindegy, hogy bizalomhiány miatt vagy szeretetünk kimutatása okán figyeljük meg a másikat!</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text">Az a tény, hogy egy másik személy minden lépésünket követi, mennyire érintene minket kényelmetlenül? Hogy éreznénk magunkat, ha esetleg számonkérné valaki, hogy miért voltunk a sarki cukrászdában, vagy miért időztünk annyit egy baráti összejövetelen?</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text">Végül pedig: van-e jogunk megfigyelni egy másik személyt, és előzetesen beszéltünk-e erről az érintettel? </p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap">
            <p class="text">Azt, hogy valaki szeretné-e használni a telefonos nyomkövetést, természetesen mindenki maga döntheti el. Viszont érdemes elgondolkodni azon, hogy milyen hatással lehet ez a megfigyelt személyre:</p>
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text">A párunk könnyen gondolhatja, hogy nem bízunk benne, kontrollálni szeretnénk, vagy alkalmatlannak tartjuk bizonyos feladatok elvégzésére, ezért szeretnénk minden percét és lépését követni. Mindez kellemetlen szituációkat, veszekedéseket szülhet, illetve sok esetben elszigetelődéshez vezethet. </p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text">A gyerekek esetében is hasonlóan ingoványos a talaj, mivel ilyen esetben a beléjük vetett bizalom elvesztését érezhetik. Ugyanígy a függetlenedési kísérleteiket is lerombolhatjuk: azt érezhetik, hogy a szülői szem és szigor mindig ott lebeg felelettük, amitől nem szabadulhatnak. </p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap">
            <p class="text">A nyomkövetésnek azonban vannak pozitív hozadékai is minden érintett szempontjából:</p>
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text">Az egyik, és talán legfontosabb előny, hogy az ,,odaértem” üzenetek helyett bármikor láthatjuk, hogy a párunk vagy a gyermekünk merre jár, vagy éppen megérkezett-e a célhelyre. </p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text">Romantikus kapcsolatban azt a kimondott-kimondatlan üzenetet hordozza, hogy a másik megbízik bennünk, nincs rejtegetnivalója előttünk.  </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text">A gyerekek szempontjából nézve az elsődleges funkció természetesen a biztonság, főleg, amikor először engedjük el őket egyedül iskolába vagy bevásárolni. </p>
                </li>
              </ul>
            </p>
            <p class="text">A nyomkövetésről tehát elmondható, hogy felelősen, kölcsönös beleegyezéssel használva sokak életét megkönnyítő funkcióként is lehet rá tekinteni, és sok felesleges aggodalomtól óvhat meg minket.  </p>
        </b-col>
      </b-row>
    </b-container>
  <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A telefonos nyomkövető mint kétélű fegyver", 
        desc: "Amikor a telefonos nyomkövetőkről beszélünk, a legtöbbünknek az aggódó szülők, vagy a féltékeny párok jutnak eszünkbe.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A telefonos nyomkövető mint kétélű fegyver",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Amikor a telefonos nyomkövetőkről beszélünk, a legtöbbünknek az aggódó szülők, vagy a féltékeny párok jutnak eszünkbe."        
    })
  }
}
</script>